import { Button, Form, Input, Modal, Radio, Spin } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import TextArea from 'antd/es/input/TextArea';
import { IZohoInvoiceItem } from '../../../../interfaces/zohoInvoices';
import { invoiceFormValidations } from '../constants';
import { IProcessInvoiceForm, IProcessInvoicePayload } from '../../../../interfaces/invoiceActions';
import { processInvoice } from '../api';

interface IProcessInvoiceModal {
  invoiceItem: IZohoInvoiceItem | null;
  setInvoiceItem?: Dispatch<SetStateAction<IZohoInvoiceItem | null>>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  fetchDetails?: () => void;
}

const ProcessInvoiceModal: FC<IProcessInvoiceModal> = ({
  invoiceItem,
  setInvoiceItem,
  isModalOpen,
  setIsModalOpen,
  fetchDetails
}) => {
  const [processInvoiceForm] = Form.useForm<IProcessInvoiceForm>();
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  useEffect(() => {
    if (invoiceItem) {
      processInvoiceForm.setFieldValue('clientId', invoiceItem?.clientId);
    }
  }, [isModalOpen]);

  const onClose = () => {
    setIsModalOpen(false);
    processInvoiceForm.resetFields();

    if (setInvoiceItem) {
      setTimeout(() => {
        setInvoiceItem(null);
      }, 500);
    }
  };

  const onSubmit = async (formValues: IProcessInvoiceForm) => {
    const payload: IProcessInvoicePayload = {
      clientId: formValues.clientId,
      action: formValues.action,
      reason: formValues.reason,
      id: invoiceItem?._id ? invoiceItem?._id : ''
    };

    await processInvoice({
      setLoading: setIsModalLoading,
      payload: payload,
      callbackFun: () => {
        onClose();
        fetchDetails && fetchDetails();
      }
    });
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={onClose}
      footer={false}
      title={`Process Invoice ( ${invoiceItem?.clientId} )`}>
      <Spin spinning={isModalLoading}>
        <Form
          initialValues={{ action: 'approved' }}
          onFinish={onSubmit}
          form={processInvoiceForm}
          scrollToFirstError
          id="processInvoiceForm">
          <div className="flex flex-col gap-2">
            <Formlabel labelText="Client Id:" required />
            <Form.Item
              name="clientId"
              rules={[
                {
                  required: true,
                  message: invoiceFormValidations?.clientId?.required
                }
              ]}>
              <Input disabled placeholder="Enter Client Id" />
            </Form.Item>
          </div>

          <div>
            <Formlabel labelText="Action:" required />
            <Form.Item
              name="action"
              className="mt-1"
              rules={[
                {
                  required: true,
                  message: invoiceFormValidations?.action?.required
                }
              ]}>
              <Radio.Group>
                <Radio value="approved" className="text-green-700 font-semibold">
                  Approve
                </Radio>
                <Radio value="rejected" className="text-red-700 font-semibold">
                  Reject
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <div>
            <Formlabel labelText="Reason:" required />
            <Form.Item
              name="reason"
              className="mt-1"
              rules={[{ required: true, message: invoiceFormValidations?.reason?.required }]}>
              <TextArea
                showCount
                maxLength={100}
                placeholder="Enter reason"
                style={{ height: 80, resize: 'none' }}
              />
            </Form.Item>
          </div>
        </Form>

        <div className="flex justify-end gap-2">
          <Button className="secondary-button w-[120px]" onClick={onClose}>
            Cancel
          </Button>
          <Button className="primary-button w-[120px]" form="processInvoiceForm" htmlType="submit">
            Submit
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default ProcessInvoiceModal;
