import React, { useEffect, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Button, Divider, Tooltip } from 'antd';
import { MdRefresh } from 'react-icons/md';
import moment from 'moment';

import { getInvoiceDetails } from '../api';
import { IInvoiceDetails } from '../../../../interfaces/invoiceListAndDetails';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import LoadingPlaceHolder from '../../../CommonComponents/LoadingPlaceHolder';
import InvoiceItemDetails from '../InvoiceItemDetails';

const InvoiceDetails = () => {
  const { id: invoiceId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<IInvoiceDetails | null>(null);
  const [basicDetails, setBasicDetails] = useState<
    {
      key: number;
      label: string;
      data: JSX.Element;
    }[]
  >();
  useEffect(() => {
    handleGetDetails();
  }, []);

  useEffect(() => {
    const basicData: {
      label: string;
      data: JSX.Element;
    }[] = [
      {
        label: 'Invoice Id',
        data: <GetCopyTextDiv text={responseData?.invoiceId} />
      },
      {
        label: 'Created Date',
        data: responseData?.createdAt ? (
          <p>{moment(responseData?.createdAt)?.format('DD/MM/YYYY, hh:mm:ss')}</p>
        ) : (
          <p>NA</p>
        )
      },
      {
        label: 'invoiceUrl',
        data: responseData?.invoiceUrl ? (
          <Tooltip title="Download Invoice">
            <Button
              onClick={() => window.open(responseData?.invoiceUrl, '_blank')}
              icon={<FaDownload size={25} />}
              className="border-none"
            />
          </Tooltip>
        ) : (
          <p>NA</p>
        )
      },
      {
        label: 'User Id',
        data: <GetCopyTextDiv text={responseData?.userId} />
      },
      {
        label: 'Client Id',
        data: <GetCopyTextDiv text={responseData?.clientId} />
      },
      {
        label: 'Invoice Number',
        data: <GetCopyTextDiv text={responseData?.invoiceNumber} />
      },
      {
        label: 'Raised On',
        data: <p>{responseData?.date || 'NA'}</p>
      },
      {
        label: 'GST No',
        data: <GetCopyTextDiv text={responseData?.gstNo} />
      },
      {
        label: 'GST Treatment',
        data: <GetCopyTextDiv text={responseData?.gstTreatment} />
      },
      {
        label: 'Tax Reg No',
        data: <GetCopyTextDiv text={responseData?.taxRegNo} />
      },
      {
        label: 'Tax Treatment',
        data: <GetCopyTextDiv text={responseData?.taxTreatment} />
      },
      {
        label: 'Customer Zoho Id',
        data: <GetCopyTextDiv text={responseData?.customerZohoId} />
      },
      {
        label: 'Place Of Supply',
        data: <GetCopyTextDiv text={responseData?.placeOfSupply} />
      },
      {
        label: 'Payment Terms Label',
        data: <p>{responseData?.paymentTermsLabel || 'NA'}</p>
      }
    ];

    const appendKey = basicData?.map((item, index) => ({ ...item, key: index + 1 }));

    setBasicDetails(appendKey);
  }, [responseData]);

  const handleGetDetails = async () => {
    if (invoiceId) {
      await getInvoiceDetails({
        setLoading: setIsLoading,
        invoiceId: invoiceId,
        setData: setResponseData
      });
    } else {
      window.close();
    }
  };
  return (
    <div>
      <div className="flex items-center gap-2 mb-5">
        <h2 className="text-xl font-bold m-0">Invoices Details: </h2>
        <Tooltip title="Refresh Details">
          <Button
            loading={isLoading}
            onClick={handleGetDetails}
            disabled={isLoading}
            icon={<MdRefresh />}
          />
        </Tooltip>
      </div>
      {isLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <div className="min-h-[300px]">
          {!!(!isLoading && responseData) && (
            <>
              <div className="flex flex-col lg:flex-row gap-5">
                <div className="bg-white py-2 px-3 md:px-5 rounded shadow  w-full lg:w-1/2 flex flex-col gap-2">
                  {basicDetails?.slice(0, 7)?.map((item) => (
                    <div key={item?.key} className="flex gap-2 flex-wrap">
                      <p className="flex gap-1 sm:min-w-[170px] justify-between">
                        <span>
                          <b>{item?.key}. </b>
                          {item?.label}
                        </span>
                        <span>:</span>
                      </p>
                      <div className="capitalize">{item?.data}</div>
                    </div>
                  ))}
                </div>

                <div className="bg-white py-2 px-3 md:px-5 rounded shadow  w-full lg:w-1/2 flex flex-col gap-2">
                  {basicDetails?.slice(7)?.map((item) => (
                    <div key={item?.key} className="flex gap-2 flex-wrap">
                      <p className="flex gap-1 sm:min-w-[170px] justify-between">
                        <span>
                          <b>{item?.key}. </b>
                          {item?.label}
                        </span>
                        <span>:</span>
                      </p>
                      <div className="capitalize">{item?.data}</div>
                    </div>
                  ))}
                </div>
              </div>

              <Divider />

              <InvoiceItemDetails responseData={responseData} />
            </>
          )}

          {!!(!isLoading && !responseData) && (
            <div className="min-h-[200px] flex justify-center items-center">
              <p className="text-red-400 tracking-wide font-semibold">
                Something went wrong, Please try again
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InvoiceDetails;
