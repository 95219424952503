import axios from "axios";
import { showMessage } from "../../../../utils/ResponseMessages";
import { FINX_CCD_API } from "../../../../utils/config";


export const finexUsersListAPI = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/user/user-list`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};
export const getUserDetails = async (userId: string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(
      `${FINX_CCD_API}/user/${userId}`,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    return response?.data?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};

export const changeUserstatus = async (body: any): Promise<any> => {

  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/user/change-status`,
      body,
      {
        headers: { Authorization: `Bearer ${token}`, },
      }
    );
    return response?.data?.data;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || 'An unexpected error occurred.';
    const errorStatus = error?.response?.status || 500;

    showMessage(errorMessage, errorStatus);
    console.error('Error changing user status:', errorMessage);
    throw error;
  }
};

export const fixedChargesListAPI = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/fixedCharge/fixedCharges-list`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};
export const CreatefixedChargesAPI = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/fixedCharge/create-fixedCharge`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};

export const changeChargeStatus = async ({ _id, status }: { _id: string; status: string }): Promise<any> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authorization token is missing.');
    }
    const body = { _id, status };
    const response = await axios.post(
      `${FINX_CCD_API}/fixedCharge/change-status`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response?.data?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};

export const pickupAddressList = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/pickupAddress/list`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};

export const CreatepickupAddressAPI = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/pickupAddress/create-pickupAddress`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};

export const uploadBookingReq = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/cart/upload-bulk`,
      payload,
      {
        headers: {'Content-Type': 'multipart/form-data', authorization: `Bearer ${token}`}
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};  

export const cartList = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/cart/list`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};

export const deleteOrder = async (orderId: string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.delete(
      `${FINX_CCD_API}/cart/${orderId}`,
    );
    return response?.data?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};


export const getbulkList = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/bulk/list`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};


export const getCartDetail = async (orderId: string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(
      `${FINX_CCD_API}/cart/${orderId}`,
    );
    return response?.data?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};

export const deleteBulk = async (bulkId:string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.delete(
      `${FINX_CCD_API}/bulk/${bulkId}`,
    );
    return response?.data?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};

export const generateBulk = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/bulk/generateBulk`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};
export const uploadAcknowledgement = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/upload/upload-Acknowledgement`,
      payload,
      {
        headers: {'Content-Type': 'multipart/form-data', authorization: `Bearer ${token}`}
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};  
export const getAddressLableLink = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/bulk/generateBulkAddress`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};
export const getRPADLableLink = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/bulk/generateBulkRpadLabel`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};
export const shipBulk = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/bulk/placeBulkOrder`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};
export const getShipmentLink = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/bulk/generateZip`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};
export const getExcelLink = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/bulk/generateExcelfile`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};
export const orderList = async (payload: any) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${FINX_CCD_API}/order/list`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    return response?.data?.data
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    throw error;
  }
};