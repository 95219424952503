import React, { FC, useState } from 'react';
import { IZohoInvoiceDetails, IZohoInvoiceItemsDetails } from '../../../../interfaces/zohoInvoices';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import ItemTaxesModal from '../ItemTaxesModal';
import { FaExternalLinkAlt } from 'react-icons/fa';

interface IItemsDetails {
  detailsData: IZohoInvoiceDetails | null;
}

const ItemsDetails: FC<IItemsDetails> = ({ detailsData }) => {
  const [taxItemDetailsModal, setTaxItemDetailsModal] = useState<boolean>(false);
  const [itemTaxData, setItemTaxData] = useState<IZohoInvoiceItemsDetails | null>(null);

  const tableColumns: ColumnsType<IZohoInvoiceItemsDetails> = [
    {
      title: 'Account Id',
      dataIndex: 'accountId',
      width: 200,
      render: (accountId) => <GetCopyTextDiv text={accountId} />
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      width: 100,
      render: (accName) => <GetCopyTextDiv text={accName} />
    },
    {
      title: 'HSN',
      dataIndex: 'hsn',
      width: 100,
      render: (hsnCode) => <GetCopyTextDiv text={hsnCode} />
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 150,
      render: (description) => <p>{description}</p>
    },
    {
      title: 'Item Taxes',
      dataIndex: 'itemTaxes',
      width: 100,
      render: (_, items) => (
        <Button
          type="primary"
          className="font-semibold"
          icon={<FaExternalLinkAlt />}
          onClick={() => {
            setItemTaxData(items);
            setTaxItemDetailsModal(true);
          }}
        />
      )
    }
  ];
  return (
    <>
      <div>
        <h2 className="text-lg font-bold m-0">Items Details:</h2>
        <Table scroll={{ x: 650 }} columns={tableColumns} dataSource={detailsData?.items} />
      </div>

      <ItemTaxesModal
        isModalOpen={taxItemDetailsModal}
        setIsModalOpen={setTaxItemDetailsModal}
        itemTaxesData={itemTaxData}
        setItemTaxesData={setItemTaxData}
      />
    </>
  );
};

export default ItemsDetails;
