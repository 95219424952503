export const addNewClientFormValidations = {
  userId: {
    required: 'Please enter user ID'
  },
  address: {
    required: 'Please enter address.',
    patternValueRegex: /^[a-zA-Z0-9\d\-_@/.,\s]+$/,
    patternMessage: 'Only , . / - _ special characters allowed'
    // maxCharactersLength: 50,
    // maxCharactersMessage: 'Maximum 50 characters allowed.'
  },
  country: {
    required: 'Please enter country name.'
  },
  website: {
    required: 'Please enter website',
    patternValueRegex: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]{1,63}(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/,
    patternMessage: 'Please enter a valid website'
  },
  postalCode: {
    required: 'Please enter postal code',
    patternValueRegex: /^[1-9][0-9]{5}$/,
    patternMessage: 'Postal code is invalid'
  },
  mobileNumber: {
    required: 'Please enter mobile number',
    patternValueRegex: /^[0-9]{10}$/,
    patternMessage: 'Please enter a valid mobile number'
  },
  cityFieldName: {
    required: 'Please enter city'
  },
  stateFieldName: {
    required: 'Please enter state'
  },
  name: {
    required: 'Please enter name'
    // maxCharactersLength: 20,
    // maxCharactersMessage: 'Maximum 20 characters allowed.'
  },
  gstNumber: {
    required: 'Please enter GST number',
    patternValueRegex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    patternMessage: 'Please enter a valid GST number'
  },
  companyName: {
    required: 'Please enter company name'
  },
  userEmail: {
    required: 'Please enter email',
    patternValueRegex: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
    patternMessage: 'Please enter valid email'
  },
  clientType: {
    required: 'Please select any type.'
  }
};

export const CLIENT_TYPES_OPTIONS = [
  'END USER',
  'FORMER BUSINESS CLIENT',
  'FORMER PARTNER CLIENT',
  'NEW BUSINESS CLIENT',
  'NEW PARTNER CLIENT',
  'FINX CLIENT'
];
