import React, { FC, useEffect, useState } from 'react';
import { IClientDetailsApiResponse } from '../../../../interfaces/invoicingEngine';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import moment from 'moment';

interface IZohoDetails {
  detailsData: IClientDetailsApiResponse | null;
}
const ZohoDetails: FC<IZohoDetails> = ({ detailsData }) => {
  const [zohoBasicDetails, setZohoBasicDetails] = useState<{
    basicDetails: {
      key: number;
      label: string;
      data: JSX.Element;
    }[];
    taxDetails: {
      key: number;
      label: string;
      data: JSX.Element;
    }[];
  }>();

  useEffect(() => {
    const basicDetails = [
      {
        label: 'Status',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.status} />
      },
      {
        label: 'First Name',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.firstName} />
      },
      {
        label: 'GST No',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.gstNo} />
      },
      {
        label: 'GST Treatment',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.gstTreatment} />
      },
      {
        label: 'Pan No',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.panNo} />
      },
      {
        label: 'Contact Id',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.contactId} />
      },
      {
        label: 'Place Of Contact',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.placeOfContact} />
      },
      {
        label: 'Created By',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.createdByName} />
      },
      {
        label: 'Created Date',
        data: (
          <p>
            {detailsData?.zohoDetails?.createdTime
              ? moment(detailsData?.zohoDetails?.createdTime).format('YYYY-MM-DD, HH:mm:ss')
              : 'NA'}
          </p>
        )
      },
      {
        label: 'Contact Category',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.contactCategory} />
      },
      {
        label: 'Contact Type',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.contactType} />
      },
      {
        label: 'Country Code',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.countryCode} />
      },
      {
        label: 'Customer Sub Type',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.customerSubType} />
      },
      {
        label: 'Payment Terms Label',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.paymentTermsLabel} />
      },
      {
        label: 'Sales Channel',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.salesChannel} />
      }
    ];
    const appendKeyToData = basicDetails?.map((item, index) => ({
      ...item,
      key: index + 1
    }));

    const taxDetails = [
      {
        key: 1,
        label: 'Tax Id',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.taxId} />
      },
      {
        key: 2,
        label: 'Tax Name',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.taxName} />
      },
      {
        key: 3,
        label: 'Tax Percentage',
        data: detailsData?.zohoDetails?.taxPercentage ? (
          <p>
            <span className="text-blue-900 font-bold pr-1">
              {detailsData?.zohoDetails?.taxPercentage}
            </span>
            %
          </p>
        ) : (
          <p>NA</p>
        )
      },
      {
        key: 4,
        label: 'Tax Treatment',
        data: <GetCopyTextDiv text={detailsData?.zohoDetails?.taxTreatment} />
      }
    ];

    setZohoBasicDetails({ basicDetails: appendKeyToData, taxDetails: taxDetails });
  }, [detailsData]);

  return (
    <>
      <div>
        <h2 className="text-lg font-bold m-0 mb-5">Details From Zoho</h2>

        <div className="flex flex-col lg:flex-row gap-5">
          <div className="bg-white py-2 px-3 md:px-5 rounded shadow  w-full lg:w-1/2 flex flex-col gap-2">
            {zohoBasicDetails?.basicDetails?.slice(0, 7)?.map((item) => (
              <div key={item?.key} className="flex gap-2 flex-wrap">
                <p className="flex gap-1 sm:min-w-[170px] justify-between">
                  <span>
                    <b>{item?.key}. </b>
                    {item?.label}
                  </span>
                  <span>:</span>
                </p>
                <div className="capitalize">{item?.data}</div>
              </div>
            ))}
          </div>

          <div className="bg-white py-2 px-3 md:px-5 rounded shadow  w-full lg:w-1/2 flex flex-col gap-2">
            {zohoBasicDetails?.basicDetails?.slice(7)?.map((item) => (
              <div key={item?.key} className="flex gap-2 flex-wrap">
                <p className="flex gap-1 sm:min-w-[170px] justify-between">
                  <span>
                    <b>{item?.key}. </b>
                    {item?.label}
                  </span>
                  <span>:</span>
                </p>
                <div className="capitalize">{item?.data}</div>
              </div>
            ))}
          </div>
        </div>

        <h2 className="text-md font-bold m-0 my-5">Tax Details</h2>

        <div className="bg-white py-2 px-3 md:px-5 flex flex-col gap-2">
          {zohoBasicDetails?.taxDetails?.map((item) => (
            <div key={item?.key} className="flex gap-2 flex-wrap">
              <p className="flex gap-1 sm:min-w-[170px] justify-between">
                <span>
                  <b>{item?.key}. </b>
                  {item?.label}
                </span>
                <span>:</span>
              </p>
              <div className="capitalize">{item?.data}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ZohoDetails;
