export const invoiceFormValidations = {
  clientId: {
    required: 'Please enter Client Id'
  },
  gstNo: {
    required: 'Please enter GST number',
    patternValueRegex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    patternMessage: 'Please enter a valid GST number'
  },
  date: {
    required: 'Please select a date'
  },
  placeOfSupply: {
    required: 'Please select any option'
  },
  reason: {
    required: 'Please enter a reason'
  },
  action: {
    required: 'Please select any action'
  }
};

export const invoiceStatus = {
  RAISED: 'raised',
  INITIATED: 'initiated',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  EXECUTED: 'executed'
};
