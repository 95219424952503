import { Button, Divider, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import { getInvoiceDetails } from '../api';
import { IZohoInvoiceDetails } from '../../../../interfaces/zohoInvoices';
import LoadingPlaceHolder from '../../../CommonComponents/LoadingPlaceHolder';
import BasicDetails from '../BasicDetails';
import ItemsDetails from '../ItemsDetails';
import { invoiceStatus } from '../constants';
import ProcessInvoiceModal from '../ProcessInvoiceModal';
import ExecuteInvoiceModal from '../ExecuteInvoiceModal';
import GetStatus from '../../../../utils/GetStatus';

const ZohoInvoicesDetails = () => {
  const { id: invoiceId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [detailsData, setDetailsData] = useState<IZohoInvoiceDetails | null>(null);
  const [processInvoiceModal, setProcessInvoiceModal] = useState<boolean>(false);
  const [executeInvoiceModal, setExecuteInvoiceModal] = useState<boolean>(false);

  useEffect(() => {
    if (invoiceId) {
      handleGetDetails();
    } else {
      navigate('/zoho-invoice-list');
    }
  }, []);

  const handleGetDetails = async () => {
    if (invoiceId)
      await getInvoiceDetails({
        setLoading: setIsLoading,
        invoiceId: invoiceId,
        setData: setDetailsData
      });
  };

  return (
    <>
      <div className="flex gap-2 items-center mb-5">
        <h2 className="text-xl font-normal m-0">Invoice Details:</h2>
        <Tooltip title="Refresh List">
          <Button onClick={handleGetDetails} loading={isLoading} icon={<MdRefresh />} />
        </Tooltip>
      </div>

      {isLoading && <LoadingPlaceHolder />}

      {!!(!isLoading && !detailsData) && (
        <div className="min-h-[200px] flex justify-center items-center">
          <p className="text-red-400 tracking-wide font-semibold">
            Something went wrong, Please try again
          </p>
        </div>
      )}

      {!!(!isLoading && detailsData) && (
        <div>
          <BasicDetails detailsData={detailsData} />

          {detailsData?.status &&
            detailsData?.status !== invoiceStatus?.RAISED &&
            detailsData?.status !== invoiceStatus?.EXECUTED &&
            detailsData?.status !== invoiceStatus?.REJECTED && (
              <div className="mt-5 flex gap-2 items-center flex-wrap">
                <h2 className="text-lg font-bold m-0">Invoice Actions :</h2>

                <GetStatus
                  currStatus={detailsData?.status}
                  PROCESSING={invoiceStatus?.INITIATED}
                  SUCCESS={invoiceStatus?.APPROVED}
                  FAILED={invoiceStatus?.REJECTED}
                  COMPLETED={
                    detailsData?.status === invoiceStatus?.RAISED
                      ? invoiceStatus?.RAISED
                      : invoiceStatus?.EXECUTED
                  }
                  CUSTOM_LABEL={detailsData?.status?.toUpperCase()?.split('_')?.join(' ')}
                  SHOW_TAGS
                />

                {detailsData?.status === invoiceStatus?.INITIATED && (
                  <Button
                    onClick={() => {
                      setProcessInvoiceModal(true);
                    }}
                    className="primary-button font-bold tracking-wide">
                    Process
                  </Button>
                )}

                {detailsData?.status === invoiceStatus?.APPROVED && (
                  <Button
                    onClick={() => {
                      setExecuteInvoiceModal(true);
                    }}
                    className="font-bold tracking-wide"
                    type="primary">
                    Execute
                  </Button>
                )}
              </div>
            )}

          <Divider />

          <ItemsDetails detailsData={detailsData} />
        </div>
      )}

      <ProcessInvoiceModal
        fetchDetails={handleGetDetails}
        invoiceItem={detailsData}
        isModalOpen={processInvoiceModal}
        setIsModalOpen={setProcessInvoiceModal}
      />

      <ExecuteInvoiceModal
        fetchDetails={handleGetDetails}
        invoiceItem={detailsData}
        isModalOpen={executeInvoiceModal}
        setIsModalOpen={setExecuteInvoiceModal}
      />
    </>
  );
};

export default ZohoInvoicesDetails;
