import { Button, Form, Input, Select, Spin, Tooltip } from 'antd';
import Modal from 'antd/es/modal/Modal';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { formRegex } from './utils';
import Formlabel from '../../../CommonComponents/FormLabel';
import { ICountry, IStateList } from '../../../../interfaces/addressApis';
import { getCountriesList, getStatesList } from '../../../../utils/addressApis';
import { IECBORegistrationPayload } from '../../../../interfaces/ecboRegistration';
import { ecboRegister } from '../api';

interface IECBORegistration {
  registrationModal: boolean;
  setRegistrationModal: Dispatch<SetStateAction<boolean>>;
}
const ECBORegistration: FC<IECBORegistration> = ({ registrationModal, setRegistrationModal }) => {
  const [registrationForm] = Form.useForm();

  const [countriesList, setCountriesList] = useState<ICountry[]>([]);
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  const [statesData, setStatesData] = useState<IStateList[]>([]);
  const [registerLoading, setRegisterLoading] = useState<boolean>(false);

  const handleCountryList = async () => {
    await getCountriesList({ setData: setCountriesList });
  };

  useEffect(() => {
    if (registrationModal) {
      handleCountryList();
    }
  }, [registrationModal]);

  const onCountryChange = async (countryCode: string) => {
    setStatesData([]);
    registrationForm.resetFields(['franchiseeAddressState']);
    await getStatesList({
      setData: setStatesData,
      countryCode: countryCode,
      setLoading: setStateLoading
    });
  };

  const onClose = () => {
    setRegistrationModal(false);
    setTimeout(() => {
      registrationForm.resetFields();
    }, 400);
  };

  const onSubmit = async (formValues: IECBORegistrationPayload) => {
    await ecboRegister({
      setLoading: setRegisterLoading,
      payload: formValues,
      callBackFunc: onClose
    });
  };

  return (
    <Modal open={registrationModal} title="ECBO Register" footer={false} onCancel={onClose}>
      <Spin spinning={registerLoading}>
        <Form layout="vertical" scrollToFirstError onFinish={onSubmit} form={registrationForm}>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <Formlabel labelText="User Email" required />
              <Form.Item
                name="userEmail"
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: formRegex?.userEmail?.required
                  },
                  {
                    pattern: RegExp(formRegex?.userEmail?.regexPattern),
                    message: formRegex?.userEmail?.regexMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter email-id" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Franchisee Id" required />
              <Form.Item
                name="franchiseeId"
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: formRegex?.franchiseeId?.required
                  }
                ]}>
                <Input type="text" placeholder="Enter franchisee id" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Franchisee Name" required />
              <Form.Item
                name="franchiseeName"
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: formRegex?.franchiseeName?.required
                  }
                ]}>
                <Input type="text" placeholder="Enter franchisee name" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Franchisee Address Line 1" required />
              <Form.Item
                className="m-0"
                name="franchiseeAddressLine1"
                rules={[
                  { required: true, message: formRegex?.address?.required },
                  {
                    max: formRegex?.address?.maxCharactersLength,
                    message: formRegex?.address?.maxCharactersMessage
                  },
                  {
                    pattern: formRegex?.address?.regexPattern,
                    message: formRegex?.address?.regexMessage
                  }
                ]}>
                <Input placeholder="Enter address" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Franchisee Address Line 2" required />
              <Form.Item
                className="m-0"
                name="franchiseeAddressLine2"
                rules={[
                  { required: true, message: formRegex?.address?.required },
                  {
                    max: formRegex?.address?.maxCharactersLength,
                    message: formRegex?.address?.maxCharactersMessage
                  },
                  {
                    pattern: formRegex?.address?.regexPattern,
                    message: formRegex?.address?.regexMessage
                  }
                ]}>
                <Input placeholder="Enter address" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Franchisee Mobile No" required />
              <Form.Item
                className="m-0"
                name="franchiseeMobileNo"
                rules={[
                  { required: true, message: formRegex?.franchiseeMobileNo?.required },
                  {
                    pattern: formRegex?.franchiseeMobileNo?.regexPattern,
                    message: formRegex?.franchiseeMobileNo?.regexMessage
                  }
                ]}>
                <Input placeholder="Enter mobile number" />
              </Form.Item>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Country" required />
                <Form.Item
                  className="m-0"
                  name="franchiseeAddressCountry"
                  rules={[{ required: true, message: formRegex?.country?.required }]}>
                  <Select
                    onChange={onCountryChange}
                    showSearch
                    options={countriesList?.map((item) => ({
                      label: `${item?.name[0]} - ${item?.codes?.iso2}`,
                      value: item?.codes?.iso2
                    }))}
                    optionFilterProp="label"
                    placeholder="Select any country"
                  />
                </Form.Item>
              </div>
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="State" required />
                <Tooltip
                  title={`${
                    stateLoading
                      ? 'Fetching states, please wait'
                      : statesData?.length
                      ? ''
                      : 'Select country'
                  }`}>
                  <Form.Item
                    className="m-0"
                    name="franchiseeAddressState"
                    rules={[{ required: true, message: formRegex?.state?.required }]}>
                    <Select
                      showSearch
                      disabled={!statesData?.length}
                      placeholder="Select any state"
                      optionFilterProp="label"
                      options={statesData?.map((item) => ({
                        label: `${item?.name} - ${item?.code}`,
                        value: item?.code
                      }))}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="City" required />
                <Form.Item
                  className="m-0"
                  name="franchiseeAddressCity"
                  rules={[{ required: true, message: formRegex?.city?.required }]}>
                  <Input type="text" placeholder="Enter any city" />
                </Form.Item>
              </div>
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Postal Code" required />
                <Form.Item
                  className="m-0"
                  name="franchiseeAddressPostalCode"
                  rules={[
                    {
                      required: true,
                      message: formRegex?.postalCode?.required
                    },
                    {
                      pattern: formRegex?.postalCode?.regexPattern,
                      message: formRegex?.postalCode?.regexMessage
                    }
                  ]}>
                  <Input type="text" placeholder="Enter Postal Code" />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-5">
            <Button type="primary" htmlType="submit">
              Register
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ECBORegistration;
