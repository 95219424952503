import BusinessOrdersList from '../../components/pages/BusinessOrdersList';
import BusinessOrdersDetails from '../../components/pages/BusinessOrdersList/Partials/BusinessOrdersDetails';
import CustomBulkOrders from '../../components/pages/CustomBulkOrders';
import BulkOrdersList from '../../components/pages/CustomBulkOrders/Partials/BulkOrdersList';
import ShipmentLabelDownload from '../../components/pages/CustomBulkOrders/Partials/ShipmentLabelDownload';
import Dashboard from '../../components/pages/Dashboard';
import Orders from '../../components/pages/Orders';
import OrderDetails from '../../components/pages/Orders/Partials/OrderDetails';
import PushFailedOrder from '../../components/pages/Orders/Partials/PushFailedOrder';
import RefundedOrdersList from '../../components/pages/Orders/Partials/RefundedOrdersList';
import BulkOrdersReport from '../../components/pages/Reports/BulkOrdersReport';
import UpdateOrders from '../../components/pages/Orders/Partials/UpdateOrders';
import BusinessOrderReport from '../../components/pages/Reports/BusinessOrderReport';
import OrdersReport from '../../components/pages/Reports/OrderReport';
import WalletReport from '../../components/pages/Reports/WalletReport';
import SellerVerification from '../../components/pages/SellerVerification';
import SignIn from '../../components/pages/SignIn';
import ChangePassword from '../../components/pages/SignIn/partials/ChangePassword';
import ForgetPassword from '../../components/pages/SignIn/partials/ForgetPassword';
import Users from '../../components/pages/Users';
import UserDetails from '../../components/pages/Users/Partials/UserDetails';
import Fintechuser from '../../components/pages/Fintech/UserDetails';
import Charges from '../../components/pages/Fintech/Charges';
import WalletTransactions from '../../components/pages/WalletTransactions';
import { IS_REFUND_ENABLED } from '../../utils/config';
import PageNotFound from '../components/PageNotFound';
import UpdateTicker from '../../components/pages/UpdateTicker';
import ManageMenu from '../../components/pages/ManageMenu';
import AddMenu from '../../components/pages/AddMenu';
import Fintech from '../../components/pages/FintechOrders';
import ManageServiceProviders from '../../components/pages/ManageServiceProviders';
import Discrepancy from '../../components/pages/Discrepancy';
import PincodeAvailability from '../../components/pages/ManagePincodes/PincodeAvailability';
import BulkOrderList from '../../components/pages/FintechOrders/BulkOrderList';
import CartDetails from '../../components/pages/FintechOrders/BulkOrderList/CartDetails';
import ShipmentLabelAndInvoice from '../../components/pages/ShipmentLabelAndInvoice';
import PincodesLogs from '../../components/pages/ManagePincodes/PincodesLogs';
import HandlePincodes from '../../components/pages/ManagePincodes/HandlePincodes';
import DownloadTracking from '../../components/pages/ManualTracking/DownloadTracking';
import UploadTracking from '../../components/pages/ManualTracking/UploadTracking';
import B2BOnboarding from '../../components/pages/B2BOnboarding';
import CompanyList from '../../components/pages/B2BVerification/CompanyList';
import CompanyDetails from '../../components/pages/B2BVerification/CompanyDetails';
import UploadBookingReq from '../../components/pages/FintechOrders/UploadBookingReq';
import FiexdCharges from '../../components/pages/Charges/FixedCahrges';
import B2BOrdersList from '../../components/pages/B2BOrders/B2BOrdersList';
import B2BOrderDetails from '../../components/pages/B2BOrders/B2BOrderDetails';
import B2BBulkOrder from '../../components/pages/B2BOrders/B2BBulkOrder';
import B2BBulkOrdersList from '../../components/pages/B2BOrders/B2BBulkOrdersList';
import B2BBulkOrderDetails from '../../components/pages/B2BOrders/B2BBulkOrderDetails';
import UploadAcknowledgement from '../../components/pages/FintechOrders/UploadAcknowledgement';
import ECBOBulkOrders from '../../components/pages/ECBOBulkOrders';
import B2BPlaceDetails from '../../components/pages/B2BOrders/B2BPlaceDetails';
import B2BAddressBook from '../../components/pages/B2BOrders/B2BAddressBook';
import ECBOBatches from '../../components/pages/ECBOBatches';
import Tracking from '../../components/pages/ManualTracking/Tracking';
import User from '../../components/pages/Fintech/User';
import UploadBooking from '../../components/pages/Fintech/UploadBookingReq';
import BulkOrderlist from '../../components/pages/Fintech/BulkOrderList';
import BookingReq from '../../components/pages/Fintech/BookingReq';
import Address from '../../components/pages/Fintech/Address';
import FintechUserList from '../../components/pages/Fintech/User';
import InvoicingEngine from '../../components/pages/InvoicingEngine';
import ClientDetails from '../../components/pages/InvoicingEngine/ClientDetails';
import InvoiceDetails from '../../components/pages/InvoicingEngine/InvoiceDetails';
import ZohoInvoiceList from '../../components/pages/ZohoInvoices';
import ZohoInvoicesDetails from '../../components/pages/ZohoInvoices/ZohoInvoicesDetails';
import Order from '../../components/pages/Fintech/Order';
import UploadAcknowledgementFinx from '../../components/pages/Fintech/uploadAcknowledgement';
import GenerateBulk from '../../components/pages/Fintech/BookingReq/generateBulk';

export const anonymous = [
  {
    routePath: '/signin',
    Component: SignIn
  },
  {
    routePath: '/forget-password',
    Component: ForgetPassword
  }
];

// add new routes here
export let authenticated = [
  {
    routePath: '/seller-verification',
    Component: SellerVerification
  },
  {
    routePath: '*',
    Component: PageNotFound
  },
  {
    routePath: '/dashboard',
    Component: Dashboard
  },
  {
    routePath: '/change-password',
    Component: ChangePassword
  },
  {
    routePath: '/orders',
    Component: Orders
  },
  {
    routePath: '/order-details/:orderId',
    Component: OrderDetails
  },
  {
    routePath: '/business-orders-list',
    Component: BusinessOrdersList
  },
  {
    routePath: '/business-order-details/:orderId',
    Component: BusinessOrdersDetails
  },
  {
    routePath: '/orders-report',
    Component: OrdersReport
  },
  {
    routePath: '/business-orders-report',
    Component: BusinessOrderReport
  },
  {
    routePath: '/bulk-orders',
    Component: CustomBulkOrders
  },
  {
    routePath: '/push-failed-order',
    Component: PushFailedOrder
  },
  {
    routePath: '/wallet',
    Component: WalletTransactions
  },
  {
    routePath: '/wallet-report',
    Component: WalletReport
  },
  {
    routePath: '/users',
    Component: Users
  },
  {
    routePath: '/user-details',
    Component: UserDetails
  },
  {
    routePath: '/shipment-label-download',
    Component: ShipmentLabelDownload
  },
  {
    routePath: '/bulk-order-report',
    Component: BulkOrdersReport
  },
  {
    routePath: '/bulk-orders-list',
    Component: BulkOrdersList
  },

  {
    routePath: '/update-orders',
    Component: UpdateOrders
  },
  {
    routePath: '/update-ticker',
    Component: UpdateTicker
  },
  {
    routePath: '/manage-menu',
    Component: ManageMenu
  },
  {
    routePath: '/add-menu',
    Component: AddMenu
  },
  {
    routePath: '/booking-request',
    Component: Fintech
  },
  {
    routePath: '/upload-bookingReq',
    Component: UploadBookingReq
  },
  {
    routePath: '/userlist-finx',
    Component: FintechUserList
  },
  {
    routePath: '/fixed-charges',
    Component: FiexdCharges
  },
  {
    routePath: '/user-finx',
    Component: User
  },
  {
    routePath: '/user-details-finx/:userId',
    Component: Fintechuser
  },
  {
    routePath: '/charges-finx/:userId',
    Component: Charges
  },
  {
    routePath: '/upload-booking-finx/:userId',
    Component: UploadBooking
  },
  {
    routePath: '/generate-bulk-finx/:userId',
    Component: GenerateBulk
  },
  {
    routePath: '/booking-request-finx/:userId',
    Component: BookingReq
  },
  {
    routePath: '/bulkOrder-list-finx/:userId',
    Component: BulkOrderlist
  },
  {
    routePath: '/pickupAddress-finx/:userId',
    Component: Address
  },
  // === Order Routes
  {
    routePath: '/order-finx',
    Component: Order
  },
  {
    routePath: '/upload-acknowledgement-finx',
    Component: UploadAcknowledgementFinx
  },
  // Order Routes
  {
    routePath: '/download-tracking',
    Component: DownloadTracking
  },
  {
    routePath: '/upload-tracking',
    Component: UploadTracking
  },
  {
    routePath: '/tracking',
    Component: Tracking
  },
  {
    routePath: '/upload-acknowledgement',
    Component: UploadAcknowledgement
  },
  {
    routePath: '/fintech/bulk-order-list',
    Component: BulkOrderList
  },
  {
    routePath: '/cart-details/:id',
    Component: CartDetails
  },
  {
    routePath: '/manage-providers',
    Component: ManageServiceProviders
  },
  {
    routePath: '/discrepancy',
    Component: Discrepancy
  },
  {
    routePath: '/handle-pincodes',
    Component: HandlePincodes
  },
  {
    routePath: '/pincode-availability',
    Component: PincodeAvailability
  },
  {
    routePath: '/shipment-label-and-invoice',
    Component: ShipmentLabelAndInvoice
  },
  {
    routePath: '/pincodes-logs',
    Component: PincodesLogs
  },
  {
    routePath: '/b2b-onboarding',
    Component: B2BOnboarding
  },
  { routePath: '/b2b-company-list', Component: CompanyList },
  { routePath: '/b2b-company-details/:id', Component: CompanyDetails },
  { routePath: '/b2b-orders-list', Component: B2BOrdersList },
  { routePath: '/b2b-order-details/:id', Component: B2BOrderDetails },
  {
    routePath: '/ecbo-bulk-orders',
    Component: ECBOBulkOrders
  },
  {
    routePath: '/ecbo-batches',
    Component: ECBOBatches
  },
  { routePath: '/b2b-bulk-order', Component: B2BBulkOrder },
  { routePath: '/b2b-bulk-orders-list', Component: B2BBulkOrdersList },
  { routePath: '/b2b-bulk-order-details/:bulkId', Component: B2BBulkOrderDetails },
  { routePath: '/b2b-places-details', Component: B2BPlaceDetails },
  { routePath: '/b2b-address-book', Component: B2BAddressBook },
  { routePath: '/clients', Component: InvoicingEngine },
  { routePath: '/clients/:id', Component: ClientDetails },
  { routePath: '/invoicing-details/:id', Component: InvoiceDetails },
  { routePath: '/zoho-invoice-list', Component: ZohoInvoiceList },
  { routePath: '/zoho-invoice-details/:id?', Component: ZohoInvoicesDetails }
];

if (IS_REFUND_ENABLED) {
  authenticated = [
    ...authenticated,
    {
      routePath: '/refunded-orders',
      Component: RefundedOrdersList
    }
  ];
}
