import { Button, Form, Input, Select, Spin, notification } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { uploadManualTracking } from '../api';

const UploadTracking: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [trackingData, setTrackingData] = useState<number[]>([]);
  const [providerName, setProviderName] = useState('');
  const [trackingForm] = Form.useForm();
  const DELIVERY_VENDORS_LIST = ['India Post'];

  const onSubmitForm = async () => {
    if (!file) {
      notification.error({ message: 'No file selected' });
      return;
    }
    try {
      setIsLoading(true);
      await uploadManualTracking({
        file: file,
        providerName
      });
      notification.success({ message: 'Tracking data uploaded successfully' });
      onClickRemove();
    } catch (error) {
      console.log(error, 32);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    if (event.target.files?.length) {
      setFileName(event.target.files[0]?.name);
      setFile(event.target.files[0]);
      setTrackingData([1]);
    } else {
      setFileName('');
      setFile(null);
      setTrackingData([]);
    }
    setIsLoading(false);
  };

  const onClickRemove = () => {
    setFile(null);
    setFileName('');
    setTrackingData([]);
    trackingForm.resetFields();
  };

  const onClickSubmit = async () => {
    await trackingForm.validateFields().then(onSubmitForm);
  };

  return (
    <>
      <h2 className="text-xl font-normal m-0 mt-2">Upload Tracking</h2>

      <div className="flex flex-col items-center">
        <div className="my-5 w-[90%] md:w-[500px] primary-box-shadow rounded-xl">
          <Spin spinning={isLoading}>
            <div className="p-5 bg-white opacity-70 rounded-xl">
              <Form
                onFinish={onClickSubmit}
                id="trackingForm"
                form={trackingForm}
                layout="vertical">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please upload a File!'
                    }
                  ]}
                  label="Upload CSV/Excel(XLXS) File"
                  name="trackings">
                  <div className="flex flex-col md:flex-row items-center gap-2">
                    <Input
                      disabled={isLoading}
                      className="w-[100px] pl-1"
                      type="file"
                      id="trackingFile"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileChange}
                    />
                    <div title={fileName}>
                      {fileName.length > 20 ? fileName.slice(0, 20) + '...' : fileName}
                      {file && (
                        <Button
                          onClick={onClickRemove}
                          title="Remove file"
                          className="border-none"
                          icon={<MdOutlineClose />}
                        />
                      )}
                    </div>
                  </div>
                </Form.Item>

                {file && (
                  <Form.Item
                    name="providerName"
                    label="Provider Name"
                    rules={[
                      {
                        required: true,
                        message: 'Select any provider'
                      }
                    ]}>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select Provider"
                      onChange={(value) => setProviderName(value)}
                      options={DELIVERY_VENDORS_LIST.map((item) => ({ value: item, label: item }))}
                    />
                  </Form.Item>
                )}

                <Button
                  disabled={!trackingData.length}
                  className="mt-5 primary-button"
                  htmlType="submit"
                  form="trackingForm">
                  Submit
                </Button>
              </Form>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};
export default UploadTracking;
