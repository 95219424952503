import { Button, Input, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getbulkList, getUserDetails } from '../api';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import HandleBulkOrderList from './HandleBulkOrderList';

const BulkOrderlist: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>(null);
  const [bulkList, setBulkList] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('Open To Ship');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(30);
  const [bulkListLoading, setBulkListLoading] = useState<boolean>(false);
  const [selectedRaws, setSelectedRaws] = useState<any[]>([]);
  const [filters, setFilters] = useState({});
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchUserDetails = async () => {
    if (userId) {
      try {
        const response = await getUserDetails(userId);
        console.log("User Details:", response);
        setUserData(response.userDetail);
      } catch (error) {
        console.error(error);
        setUserData({ name: "Unknown User", email: "Unknown Email" });
      }
    }
  };

  useEffect(() => {
    getBulk();
  }, [activeTab]);

  const getBulk = async (status = activeTab, resetFilters = false) => {
    const params = resetFilters
      ? { pageNumber, pageLimit, userId, status }
      : { ...filters, pageNumber, pageLimit, userId, status };
  
    try {
      setBulkListLoading(true);
      setHasError(false); 

      const bulkListApiRes = await getbulkList(params);
      setBulkList(bulkListApiRes.list);
    } catch (error: any) {
      console.error("Error fetching bulk orders:", error);
  
      if (error.response?.status === 404) {
        setBulkList([]);
      } else {
        setHasError(true);
      }
    } finally {
      setBulkListLoading(false);
    }
  };
  
  const refreshData = async () => {
    if (userId) {
      setBulkListLoading(true);
      await getBulk();
      setBulkListLoading(false);
    }
  };

  const clearFilters = async () => {
    setFilters({});
    setPageNumber(1); 
    await getBulk(activeTab, true); 
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
      refreshData();
    }
  }, [userId]);

  const tabs = [
    {
      key: 'Open To Ship',
      label: 'Open To Ship',
      children: (
        <HandleBulkOrderList
          currentPortalName="Ship To"
          bulkOrderList={bulkList}
          setActiveTab={setActiveTab}
          activeKey={activeTab}
          setSelectedRaws={setSelectedRaws}
          isLoading={bulkListLoading}
          setIsLoading={setIsLoading}
          fetchBulkOrders={getBulk} 
        />
      ),
    },
    {
      key: 'In-Process',
      label: 'In-Process',
      children: (
        <HandleBulkOrderList
          currentPortalName="In-Process"
          isLoading={bulkListLoading}
          bulkOrderList={bulkList}
          setActiveTab={setActiveTab}
          activeKey={activeTab}
          setSelectedRaws={setSelectedRaws}
          setIsLoading={setIsLoading}
          fetchBulkOrders={getBulk} 
        />
      )
    },
    {
      key: 'Shipment Placed',
      label: 'Shipment Placed',
      children: (
        <HandleBulkOrderList
          currentPortalName="Ship"
          isLoading={bulkListLoading}
          bulkOrderList={bulkList}
          setActiveTab={setActiveTab}
          activeKey={activeTab}
          setSelectedRaws={setSelectedRaws}
          setIsLoading={setIsLoading}
          fetchBulkOrders={getBulk} 
        />
      )
    },
  ];

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
    getBulk(tab);
  };

  return (
    <div>
      <Button type="link" onClick={() => navigate("/userlist-finx")}>
        {"<"}
        <span className="hover:underline pl-1">Back to User List</span>
      </Button>
      <div className="mb-4 flex items-center justify-between">
        <div className='flex items-center' style={{ gap: '10px' }}>
          <h2 className="text-xl font-normal m-0">
            Bulk Order List for:{" "}
            {userData && userData.firstName ? (
                  `${userData.firstName.trim().charAt(0).toUpperCase()}${userData.firstName.trim().slice(1).toLowerCase()}`
            ) : (
              "Loading..."
            )}
          </h2>
        </div>
        <div className="flex items-center">
          <Button size="large" onClick={refreshData} className="mr-2" icon={<ReloadOutlined className="" />}>
            Refresh
          </Button>
          <Button size="large" onClick={clearFilters} className="ml-2"  type='default' danger>
            Clear Filters
          </Button>
        </div>
      </div>

      <div className='fintech-user-tab'>
        <Tabs size='middle' activeKey={activeTab} onChange={onTabChange} items={tabs} />
      </div>
    </div>
    
  );
};

export default BulkOrderlist;
